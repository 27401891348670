<template>
  <el-dialog
    v-model="dialogVisible"
    :title="form.code ? '编辑包厢' : '新增包厢'"
    width="500px"
    :close-on-click-modal="false"
  >
    <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="包厢号" prop="roomName">
        <el-input v-model="form.roomName" placeholder="请输入包厢号" />
      </el-form-item>
      <el-form-item label="包厢类型" prop="roomTypeCode">
        <el-select v-model="form.roomTypeCode" placeholder="请选择包厢类型">
          <el-option v-for="item in packageTypes" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="MAC地址" v-if="form.code">
        <el-input v-model="form.macAddress" placeholder="请输入MAC地址" :disabled="!!form.code" />
      </el-form-item>
      <el-form-item label="IP地址" v-if="form.code">
        <el-input v-model="form.ipAddress" placeholder="请输入IP地址" :disabled="!!form.code" />
      </el-form-item>
      <el-form-item label="虚拟包厢" prop="isVirtual">
        <el-switch v-model="form.isVirtual" />
      </el-form-item>
      <el-form-item label="状态" prop="isShow">
        <el-switch v-model="form.isShow" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import API from '../../../service/api'
import { ref, defineComponent, watch } from 'vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'PackageForm',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    storeCode: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'success'],
  setup(props, { emit }) {
    const dialogVisible = ref(props.modelValue)
    const formRef = ref(null)
    const packageTypes = ref([])

    const form = ref({
      roomCode: '',
      roomName: '',
      roomTypeCode: '',
      roomTypeName: '',
      isVirtual: false,
      isShow: true,
    })

    const rules = {
      roomName: [{ required: true, message: '请输入包厢名称', trigger: 'blur' }],
      roomTypeCode: [{ required: true, message: '请选择包厢类型', trigger: 'change' }],
    }

    const getPackageTypes = async () => {
      const res = await API.getBoxTypeList({
        storeCode: props.storeCode,
        isEnable: 1,
      })
      if (res.code == 200) {
        packageTypes.value = res.data
      }
    }
    getPackageTypes()

    const handleSubmit = async () => {
      if (!formRef.value) return

      await formRef.value.validate(async valid => {
        if (valid) {
          const data = {
            ...form.value,
            storeCode: props.storeCode,
            isVirtual: form.value.isVirtual ? 1 : 0,
            isShow: form.value.isShow ? 1 : 0,
            roomTypeName: packageTypes.value.find(a => a.code == form.value.roomTypeCode).name,
          }
          let res
          if (data.code) {
            res = await API.updateBox(data)
          } else {
            res = await API.addBox(data)
          }
          if (res.code == 200) {
            emit('success', data)
            dialogVisible.value = false
          } else {
            ElMessage.error(res.msg)
          }
        }
      })
    }

    watch(
      () => props.modelValue,
      val => {
        dialogVisible.value = val
      },
    )

    watch(
      () => dialogVisible.value,
      val => {
        emit('update:modelValue', val)
      },
    )

    watch(
      () => props.formData,
      val => {
        if (val) {
          form.value = { ...val }
        }
      },
      { immediate: true },
    )

    return {
      dialogVisible,
      formRef,
      form,
      rules,
      packageTypes,
      handleSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
</style>
